<template>
	<div class="widget-min-box" :widget-id="widget.id" :widget-type="widget.type">
		<slot v-if="widgetLoad">
			<div class="widget-min-box-content text-center p-2">
				<div class="widget-loading"></div>
			</div>
		</slot>
		<slot v-else>
			<template v-if="Object.keys(widget).length">
				<div class="widget-min-box-list" :class="{'min-show': responsive.rightSection}">

					
					<div class="list-widget list-dash-items list-widget-structure">
						<div class="list-row l-head">
							<div class="list-col l-item">
								<span class="width-fix">ترتیب</span>
							</div>
							<div class="list-col l-title">
								<span>فهرست وظایف روزانه</span>
							</div>
							<div class="list-col l-item"><span class="width-fix">ویرایش</span></div>
							<div class="list-col l-item"><span class="width-fix">حذف</span></div>
						</div>
						<draggable v-model="todos" group="todos" @start="drag=true" @end="saveTodosSort()" ghost-class="drag-ghost" handle=".list-drag">
							<div v-for="todo in todos" :key="todo.id" class="list-row item-draggable hover bold">
								<div class="list-col l-item pointer">
									<i class="uil uil-arrows-v list-drag"></i>
								</div>
								<div class="list-col l-title l-title-widget-structure" @click="openTodo(todo.id); makeResponsive(1);">
									<span>{{ todo.title }}</span>
								</div>
								<div class="list-col l-item pointer" @click="open_todo_edit_modal(todo.id)">
									<i class="uil-edit-alt"></i>
								</div>
								<div class="list-col l-item red-color pointer" @click="removeTodo(todo.id)">
									<i class="uil uil-trash remove-row"></i>
								</div>
							</div>
						</draggable>
					</div>

					<div class="list-widget-add-container">
						<i class="fa fa-plus list-widget-add-icon" aria-hidden="true" @click="addTodo()"></i>
						<input class="list-widget-input-add" type="text" placeholder="افزودن لیست جدید" v-model="newTodo" @keyup.enter="addTodo()">
					</div>
				</div>

				<div class="widget-min-box-content" :class="{'min-show': responsive.leftSection}">
					<div class="list-row l-head">
						<div class="list-col l-item">
							<span class="uil uil-arrow-right widget-min-back-to-list" @click="makeResponsive(2)"></span>
						</div>
						<div class="list-col l-title">
							<span v-if="activeTodo && activeTodo.title">{{ activeTodo.title }}</span>
							<!-- <i class="uil-edit-alt edit-todo" v-if="activeTodo && activeTodo.title" @click="open_todo_edit_modal()"></i> -->
						</div>
						<div class="list-col l-item pointer hover head-color show-widget-settings-modal" v-if="editable && wrapper != 'dashboard'">
							<!-- <span>
								<div class="fa fa-cog l-item-btn-pages"></div>
								تنظیمات
							</span> -->
							<div class="form-row checkbox-modal-list ml-1 p-2">
								<input type="checkbox" class="custom-control-input todo-check-show" id="customControlValidation1" v-model="showChecked">
								<label class="custom-control-label" for="customControlValidation1">نمایش موارد انجام شده</label>
							</div>
						</div>
					</div>
					<div class="tgju-widget-content full-padding">
						<div class="list-widget-search-container">
							<i class="fa fa-search list-widget-search-icon" aria-hidden="true"></i>
							<input class="list-widget-search" type="text" placeholder="جستجو در لیست ..." v-model="todoFilterInput" @keyup="filterTodo">
							<!-- <i class="fa fa-chevron-left list-widget-search-arrow-icon" aria-hidden="true"></i> -->
						</div>

						<div class="list-widget list-dash-items list-widget-structure todo-list-checkbox" :class="{ 'checked-show': showChecked }">
							<draggable v-model="activeTodo.items" group="todosItems" @start="drag=true" @end="saveTodoItem()" ghost-class="drag-ghost" v-if="activeTodo">
								<template v-for="todoItem in activeTodo.items">
									<div v-if="!todoItem.hide" :key="todoItem.id" class="list-row item-draggable hover bold" :class="{'checked': todoItem.checked}">
										<div class="list-col l-item pointer no-border">
											<input class="option-input checkbox todo-check" @click="checkTodoItem(todoItem.id)" type="checkbox" :checked="todoItem.checked">
										</div>
										<div class="list-col l-title l-title-widget-structure show-widget-info" @click="triggerTodoPanel(todoItem.id); makeResponsive(3);">
											<span class="pr-0 checkbox-title">{{ todoItem.title }}</span>
										</div>
										<div class="list-col l-item pointer">
											<i class="fa" :class="{'fa-star active-star': todoItem.star, 'fa-star-o': !todoItem.star}" @click="starTodoItem(todoItem.id)"></i>
										</div>
										<div class="list-col l-item pointer show-widget-info" @click="triggerTodoPanel(todoItem.id)">
											<i class="uil uil-angle-left-b"></i>
										</div>
									</div>
								</template>
							</draggable>
						</div>

						<div class="list-widget-add-container">
							<i class="fa fa-plus list-widget-add-icon-todo" aria-hidden="true" @click="addTodoItem()"></i>
							<input class="list-widget-todo-add" type="text" placeholder="افزودن وظیفه جدید" v-model="newTodoItem" @keyup.enter="addTodoItem()">
						</div>
					</div>
				</div>

				<div class="widget-min-box-info min-show" :class="{'min-show': responsive.leftPanel}" v-if="todoPanelOpen && activeTodoItem">
					<div class="list-widget list-dash-items list-widget-structure">
						<div class="list-row l-head">
							<div class="list-col l-item pointer hover head-color" @click="triggerTodoPanel(activeTodoItem.id)">
								<i class="uil uil-arrow-right close-widget-info" @click="makeResponsive(1)"></i>
							</div>
							<div class="list-col l-title">
							</div>
							<div class="list-col l-item pointer red-color hover head-color" @click="removeTodoItem(activeTodoItem.id)">
								<span>
									<div class="uil uil-trash-alt close-todo-btn l-item-btn-pages"></div>
								</span>
							</div>
						</div>

						<div class="list-row bold">
							<div class="list-col l-item">
								<i class="uil-edit-alt"></i>
							</div>
							<div class="list-col l-title">
								<input class="list-input" type="text" placeholder="عنوان تسک" v-model="activeTodoItem.title" @blur="saveTodoItem()">
							</div>
						</div>

						<div class="list-row bold">
							<div class="list-col l-item">
								<i class="uil uil-calendar-alt"></i>
							</div>
							<div class="list-col l-title">
								<date-picker
									v-model="activeTodoItem.date"
									placeholder="افزودن تاریخ"
									appendTo="body"
									inputClass="list-input Datepicker"
									:clearable="true"
									@change="saveTodoItem()"
								></date-picker>
							</div>
						</div>

						<div class="list-row bold">
							<div class="list-col l-item">
								<i class="uil uil-paperclip"></i>
							</div>
							<div class="list-col l-title position-relative">
								<span class="position-absolute">پیوست فایل</span>
								<input class="list-input select-file" type="file" ref="todo_attachments" @change="uploadAttachment($event.target.files)">
							</div>
						</div>

						<div class="list-row atachments" v-if="activeTodoItem.attachments.length">
							<div v-for="attachment in activeTodoItem.attachments" :key="attachment.id">
								<span class="uil uil-trash remove-row" @click="removeAttachment(attachment.id)"></span>
								<span  class="badge badge-secondary atachments-items pointer" @click="downloadAttachment(attachment.id)">
									{{ attachment.title }}
								</span>
							</div>
							
						</div>
						<textarea class="list-row-textarea" placeholder="افزودن یادداشت" v-model="activeTodoItem.note" @blur="saveTodoItem()"></textarea>

					</div>
				</div>
			</template>
			
			<template v-else>
				<div class="widget-min-box-content">
					<div class="text-center p-2">
						<div class="widget-loading"></div>
					</div>
				</div>
			</template>
		</slot>
	</div>
</template>

<style lang="scss">
	.vpd-main {
		padding: 0 !important;
		
		label {
			display: none;
		}

		.vpd-input-group {
			padding: 0 !important;
			padding-right: 11px !important;

			.vpd-clear-btn {
				top: 4px;
			}
		}

		.Datepicker {
			padding: 0 !important;
		}
	}

	.widget-min-box-content {
		.l-title {
			display: flex;
		}

		.edit-todo {
			font-size: 14px;
			cursor: pointer;
			margin-right: -3px;
		}
	}
</style>

<script>
	// این کامپوننت اجزای ویجت وظایف روزانه در خود جای میدهد
	import draggable from 'vuedraggable'

	export default {
		name: 'Todo',
		props: ['widget', 'data', 'editable', 'wrapper'],
		components: {
			draggable
		},
		data() {
			return {
				todos: [],
				activeTodo: null,
				activeTodoItem: null,
				newTodo: '',
				newTodoItem: '',
				todoFilterInput: '',
				todoPanelOpen: false,
				showChecked: false,
				todoPanelIndex: null,
				responsive: {
					rightSection: true,
					leftSection: false,
					leftPanel: false,
				},
				widgetLoad: true,
			}
		},

		mounted() {
			this.$watch('data', function(newValue, oldValue) {
				if ( Object.keys(newValue).length && ( typeof oldValue == 'undefined' || !Object.keys(oldValue).length ) ) {
					// تزریق داده های ورود به کامپوننت
					this.loadData();
				}
			}, {immediate: true});
		},

		methods: {	
			// این تابع وظیفه تزریق داده های ورودی به کامپوننت را دارد	
			loadData() {
				this.todos = this.data.todos;

				if ( this.todos.length ) {
					this.activeTodo = this.todos[0];
				}

				this.widgetLoad = false;
			},
			// این متد وظیفه ی حذف یک وظیفه از لیست وظیفه ها را دارد
			removeTodo(id) {
				this.$helpers.confirm('هشدار حذف!', {
					message: 'آیا از حذف این مورد اطمینان دارید؟',
				}).then((confirmed) => {
					if ( confirmed ) {
						let index = this.$helpers.getIndex(this.todos, id);

						this.todos.splice(index, 1);

						let next_index = index - 1;
						if ( next_index < 0 )
							next_index = 0;

						if(this.todos.length > 0) {
							this.openTodo(this.todos[next_index].id);
						} else {
							this.activeTodo = null;
							this.activeTodoItem = null;
						}
						// ارسال درخواست به وب سرویس	
						this.$helpers.makeRequest('DELETE', '/todo/delete/' + id).then(api_response => {
							this.$helpers.api_alert(api_response);

							if ( api_response.status == 200 ) {
								//
							}
						});	
					}
				});
			},
			// این متد وظیفه این را دارد پس از کلیک روی وظیفه مورد نظر آیتم های آن را لود میکند
			openTodo(id) {
				let index = this.$helpers.getIndex(this.todos, id);

				this.activeTodo = this.todos[index];

				this.todoPanelOpen = false;
			},
			// این متد وظیفه این را دارد که یک وظیفه جدید را اضافه میکند
			addTodo() {
				if ( !this.newTodo ) return;

				let id = 'id-' + new Date().getTime();

				let index = this.todos.push({
					id,
					title: this.newTodo,
					items: []
				});

				let api_data = {
					title: this.newTodo,
					items: null,
				};

				this.$helpers.makeRequest('POST', '/todo/save', api_data).then(api_response => {
					this.$helpers.api_alert(api_response);

					if ( api_response.status == 200 ) {
						let inserted_id = api_response.data.response.id;

						this.todos.forEach((todo, index) => {
							if ( todo.id == id ) {
								todo.id = inserted_id;
							}
						});
					}
				});

				this.newTodo = '';
				this.openTodo(id);
			},
			// این متد برای ستاره دار کردن یکی از آیتم های وظیفه می باشد
			starTodoItem(id) {
				let index = this.$helpers.getIndex(this.activeTodo.items, id);

				this.activeTodo['items'][index]['star'] = !this.activeTodo['items'][index]['star'];

				this.saveTodoItem();
			},
			// این متد برای تیک دار کردن یکی از آیتم های وظیفه می باشد 
			checkTodoItem(id) {
				let index = this.$helpers.getIndex(this.activeTodo.items, id);

				this.activeTodo['items'][index]['checked'] = !this.activeTodo['items'][index]['checked'];

				this.saveTodoItem();
			},
			// این متد وظیفه اپلود فایل اتچ شده به آیتم مورد نظر از وظیفه انتخاب شده
			uploadAttachment(fileList) {
				let attachment = [];
				if ( fileList.length ) {
					attachment = fileList[0];
				} else {
					attachment = null;
				}

				if ( attachment ) {
					let api_data = {
						attachment
					};

					this.$helpers.makeRequest('POST', '/todo/upload', api_data).then(api_response => {
						this.$helpers.api_alert(api_response);

						if ( api_response.status == 200 ) {

							Vue.prototype.$toast.success({
								title : 'موفق',
								message : 'با موفقیت بارگزاری شد',
							});

							let response = api_response.data.response;

							this.activeTodoItem.attachments.push(response);

							this.saveTodoItem();
						}
					});
				}
			},
			// این متد برای حذف فایل آپلود شده
			removeAttachment(attach_id) {
				
				let api_data = {
					todo_id: this.activeTodo.id,
					todo_item_id : this.activeTodoItem.id,
					attach_id : attach_id,
					type : 'remove',
				};
				

				this.$helpers.makeRequest('GET', `/todo/download-or-remove`,api_data).then(api_response => {
					this.$helpers.api_alert(api_response);

					if ( api_response.status == 200 ) {
						let index = this.$helpers.getIndex(this.activeTodoItem.attachments, attach_id);
				
						this.activeTodoItem.attachments.splice(index, 1);

						this.saveTodoItem();

						Vue.prototype.$toast.success({
							title : 'موفق',
							message : 'با موفقیت حذف شد',
						});
					}
				});

				
			},
			// این متد جهت دانلود فایل آپلود شده می باشد
			downloadAttachment(attach_id) {

				// let url = `/todo/download?todo_id=${this.activeTodo.id}&todo_item_id=${this.activeTodoItem.id}&attach_id=${attach_id}`;
				// this.$helpers.downloadFile(url);

				let api_data = {
					todo_id: this.activeTodo.id,
					todo_item_id : this.activeTodoItem.id,
					attach_id : attach_id,
					type : 'download',
				};

				this.$helpers.makeRequest('GET', `/todo/download-or-remove`,api_data).then(api_response => {
					this.$helpers.api_alert(api_response);

					if ( api_response.status == 200 ) {
						window.open(api_response.data.link,'_blank');
					}
				});
			},

			// این متد برای فیلتر کردن لیست وظایف آنها استفاده میشود
			filterTodo() {
				this.activeTodo['items'].forEach((todoItem, index) => {
					if ( this.todoFilterInput.trim() == '' ) {
						this.activeTodo['items'][index]['hide'] = false;
					} else if ( todoItem.title.indexOf(this.todoFilterInput.trim()) > -1 ) {
						this.activeTodo['items'][index]['hide'] = false;
					} else {
						this.activeTodo['items'][index]['hide'] = true;
					}
				});
			},

			triggerTodoPanel(id) {
				let index = this.$helpers.getIndex(this.activeTodo.items, id);

				if ( index != this.todoPanelIndex ) {
					this.todoPanelOpen = true;
				} else {
					this.todoPanelOpen = !this.todoPanelOpen;
				}

				this.todoPanelIndex = index;
				this.activeTodoItem = this.activeTodo['items'][index];
			},

			// این متد برای حذف آیتم های لیست وظایف استفاده میشود
			removeTodoItem(id) {
				let index = this.$helpers.getIndex(this.activeTodo.items, id);

				this.todoPanelIndex = null;
				this.todoPanelOpen = false;
				this.activeTodo.items.splice(index, 1);

				this.saveTodoItem();
			},

			// این متد وظیفه هندل کردن افزودن آیتم به لیست وظایف را دارا می باشد
			addTodoItem() {
				if(this.newTodoItem) {
					this.activeTodo.items.push({
						id: 'id-' + new Date().getTime(),
						title: this.newTodoItem,
						note: '',
						date: null,
						star: false,
						attachments: [],
						checked: false,
						hide: false
					});

					this.newTodoItem = '';

					this.saveTodoItem();
				}
			},
			// این متد درخواست ذخیره سازی آیتم جدید لیست وظایف را به وب سرویس ارسال میکند
			saveTodoItem() {
				let api_data = {
					items: JSON.stringify(this.$helpers.unbindObject(this.activeTodo.items)),
				};

				this.$helpers.makeRequest('POST', '/todo/item-save/' + this.activeTodo.id, api_data).then(api_response => {
					this.$helpers.api_alert(api_response);

					if ( api_response.status == 200 ) {
						//
					}
				});
			},
			// این متد جهت هندل سورت کردن و ترتیب قرار گیری ایتم های لیست وظایف استفاده میشود
			saveTodosSort() {
				let sort = [];
				this.todos.forEach(todo => {
					sort.push(todo.id);
				});

				let api_data = {
					sort: JSON.stringify(sort),
				};

				this.$helpers.makeRequest('POST', '/todo/sort', api_data).then(api_response => {
					this.$helpers.api_alert(api_response);

					if ( api_response.status == 200 ) {
						//
					}
				});
			},
			// این متد وظیفه هندل کردن مودال تنظیمات وظایف در ویجت داشبورد است
			open_settings_modal() {
				let callbackEvent = this.$router.currentRoute.name + '_save_settings_' + this.widget.id + '_' + new Date().getTime();

				let modal_data = {
					modal: 'widget_settings_modal',
					callbackEvent,
					component: 'Settings/Todo.vue',
					config: {
						data: {
							title: this.widget.title,
							showChecked: this.widget.data.showChecked,
						}
					}
				};

				modal_data = this.$helpers.unbindObject(modal_data);

				this.$root.$emit('open_modal', modal_data);
				// کال بک فانکشن
				this.$root.$on(callbackEvent, (settings) => {
					this.widget.data.showChecked = settings.showChecked;
					this.widget.title = settings.title;

					let widget = this.$helpers.unbindObject(this.widget);

					this.$helpers.saveWidget(widget);
				});
			},
			// این متد برای ویرایش عنوان وظیفه استفاده میشود // هندل کردن مودال و کال بک فاکنش
			open_todo_edit_modal(id = 0) {
				if (id > 0) {
					let index = this.$helpers.getIndex(this.todos, id);
					this.activeTodo = this.todos[index];
				}

				let callbackEvent = this.$router.currentRoute.name + '_EditTodos_' + new Date().getTime();


				let modal_data = {
					modal: 'general_modal',
					callbackEvent,
					component: 'TodoEdit.vue',
					config: {
						title: 'ویرایش وظیفه',
						icon: 'uil uil-edit-alt with-icon',
						smallModal: true,
						data: {
							todo: this.$helpers.unbindObject(this.activeTodo),
						},
					}
				};

				this.$root.$emit('open_modal', modal_data);

				// کال بک فانکشن
				this.$root.$on(callbackEvent, (response) => {
					if ( response.action == 'editTodo' ) {
						this.activeTodo.title = response.data.todo.title;

						let api_data = {
							title: this.activeTodo.title,
						};

						this.$helpers.makeRequest('POST', '/todo/edit/' + this.activeTodo.id, api_data).then(api_response => {
							this.$helpers.api_alert(api_response);

							if ( api_response.status == 200 ) {
								//
							}
						});
					}
				});
			},

			makeResponsive(mode) {
				if ( mode == 1 ) {
					this.responsive.rightSection = false;
					this.responsive.leftSection = true;
					this.responsive.leftPanel = false;
				} else if ( mode == 2 ) {
					this.responsive.rightSection = true;
					this.responsive.leftSection = false;
					this.responsive.leftPanel = false;
				} else if ( mode == 3 ) {
					this.responsive.rightSection = false;
					this.responsive.leftSection = false;
					this.responsive.leftPanel = true;
				}
			}
		}
	}
</script>